import "./App.css";
import React, { useEffect } from "react";

function App() {
  const list = [
    { id: 1, url: "./img/1.webp" },
    { id: 2, url: "./img/2.webp" },
    { id: 3, url: "./img/3.webp" },
    { id: 4, url: "./img/4.webp" },
  ];

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div>
      <div className="container">
        <div className="container-top-content">
        </div>
        <div className="container-content-cont">
          <div className="container-content-cont-title">功能特色</div>
          <div className="container-content-wrapper">
            {list.map((item, index) => {
              return (
                <img
                  className={
                    index % 2 === 0
                      ? "container-content-img-odd"
                      : "container-content-img-even"
                  }
                  style={item.id === 8 ? { top: "-151px" } : {}}
                  src={require(`./img/${item.id}.webp`)}
                  alt=""
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="footer">
        <div>邮箱 : jfk51667jjs@163.com</div>
        <div>版权所有 上海玖珠信息科技有限公司</div>
        <div>备案号：沪ICP备2021015531号-1</div>
      </div>
    </div>
  );
}

export default App;
